import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, switchMap, tap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { Week, Option, Participant, User } from '../models/models';
import { OptionData, WeekData } from '../models/datamodels';
import * as firebase from 'firebase';
import { isError } from 'util';

@Injectable({
  providedIn: 'root'
})
export class WeekService {


  constructor(private afs: AngularFirestore, private auth: AngularFireAuth) {
  }

  /* OPERATIONS */

  withUser = (val: string) => this.auth.user.pipe(
    map(user => {
      if (!user) return undefined;
      return val;
    }));
  weekIdToWeek = (weekId: string): Observable<Week> => {
    if (!weekId) return undefined;
    return this.afs.doc<WeekData>(`weeks/${weekId}`).valueChanges({ idField: 'id' }).pipe(
      //  tap(weekData => { console.log(JSON.stringify(weekData)) }),
      map(weekData => this.dataToWeekMapper(weekData))
    );
  }
  weeks = (): Observable<Week[]> => {
    return this.afs.collection<WeekData>(`weeks`, (_) => _.orderBy('created', 'desc')).valueChanges({ idField: 'id' }).pipe(
      map(weeks => {
        return weeks.map(week => this.dataToWeekMapper(week))
      })
    );
  }




  /* REQUESTS */

  getWeek(weekId$: Observable<string>): Observable<Week> {
    return weekId$.pipe(
      switchMap(this.withUser),
      switchMap(this.weekIdToWeek)
    );
  }

  getWeeks(): Observable<Week[]> {
    return of('').pipe(
      switchMap(this.withUser),
      switchMap(this.weeks),
    )
  }

  updateMyPersonalValues(user: User, weekId: string) {
    const update = {
      [`participants.${user.uid}.perkValues`]: user.perkValues ?? {},
      [`participants.${user.uid}.name`]: user.displayName,
      [`participants.${user.uid}.updated`]: firebase.default.firestore.FieldValue.serverTimestamp(),
    };

    this.afs.doc(`weeks/${weekId}`).update(update);
  }

  delete(weekId: string): Promise<void> {
    return this.afs.doc(`weeks/${weekId}`).delete();
  }

  toggleLock(weekId: string, lock: boolean) {
    return this.afs.doc(`weeks/${weekId}`).update({ open: lock });
  }



  async addWeek(week: Week) {
    const weekData = this.weekToDataMapper(week);
    weekData.created = firebase.default.firestore.FieldValue.serverTimestamp();
    return this.afs.collection('weeks').add(weekData);
  }


  async play(user: User, key: number, week: Week) {

    let update: any = {
      ['participants.' + user.uid]: {
        id: user.uid,
        name: user.displayName,
        time: firebase.default.firestore.FieldValue.serverTimestamp(),
        sjaak: false,
        perkValues: user.perkValues ?? {}
      }
    };
    week.options.forEach((o, i) => {
      update['options.' + i + '.joined'] = key === i ?
        firebase.default.firestore.FieldValue.arrayUnion(user.uid) :
        firebase.default.firestore.FieldValue.arrayRemove(user.uid);
    });
    console.log(update);
    this.afs.doc(`weeks/${week.id}`).update(update);
  }

  /* MAPPERS */

  weekToDataMapper(week: Week): WeekData {
    const weekData: WeekData = {} as WeekData;
    weekData.open = true;
    weekData.title = week.title;
    weekData.options = {} as any;
    week.options.forEach((shift, index) => {
      weekData.options['' + index] = {
        description: shift.description,
        max: shift.max,
        period: shift.period
      } as OptionData;
    });
    return weekData;
  }

  dataToWeekMapper(weekData: WeekData): Week {
    const week: Week = {} as Week;
    week.id = weekData.id;
    week.title = weekData.title;
    week.open = weekData.open;
    week.created = (weekData.created as any)?.toDate();

    const participantKeys = Object.keys(weekData.participants ?? {});
    week.participants = participantKeys.map(key => weekData.participants[key] as Participant);

    const optionKeys = Object.keys(weekData.options ?? {});
    week.options = optionKeys.map(key => weekData.options[key] as Option);
    return week;
  }
}
