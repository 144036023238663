<div class="row">
    <span *ngFor="let joiner of firsts; let i = index"
        [style.color]="joiner.perkValues && joiner.perkValues.Color ? joiner.perkValues.Color : 'initial'"
        [ngClass]="joiner.id === me ? 'me': ''">{{i+1}}:{{joiner.name}}</span>
</div>

<ng-container *ngIf="reserve.length > 0">
    <span class="title"><b>Reserve:</b></span>
    <div class="row">
        <span *ngFor="let joiner of reserve; let i = index" [ngClass]="joiner.id === me ? 'me': ''"
            [style.color]="joiner.perkValues && joiner.perkValues.Color ? joiner.perkValues.Color : 'initial'">{{i+1}}:{{joiner.name}}</span>
    </div>
</ng-container>
<!--
<div class="row">
    <span style="width:100%; font-size: smaller;"><b>🐝 Erbij:</b></span>

</div>

<span *ngFor="let joiner of firsts; let i = index" style="font-size: 12px;">{{i+1}} {{joiner.name}},</span>

<div class="row">
    <span style="width:100%; font-size: smaller;"><b>Reserve:</b></span>
    <span *ngFor="let joiner of reserve; let i = index" style="font-size: smaller;">{{i+1}} {{joiner.name}},</span>
</div>
-->