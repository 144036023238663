import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, combineLatest } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import * as firebase from 'firebase';

import { ActivatedRoute } from '@angular/router';

import { Title } from '@angular/platform-browser';

import { Participant, User, Week } from '../../models/models';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { WeekService } from '../../services/week.service';
import { UserService } from '../../services/user.service';
import { BaseComponent } from '../../shared/base/base.component';

@Component({
  selector: 'tovo-pick',
  templateUrl: './pick.component.html',
  styleUrls: ['./pick.component.scss']
})
export class PickComponent extends BaseComponent implements OnInit, OnDestroy {


  title = "Inschrijven";
  week: Week;
  user: User;
  key: string;
  key$: Observable<String>;
  week$: Observable<Week>;
  user$: Observable<any>;
  hasNotifications: boolean = false;
  notificationEnabled: boolean = false;

  constructor(
    private weekService: WeekService,
    private userService: UserService,
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private titleService: Title,
    private afMessaging: AngularFireMessaging,
    private analytics: AngularFireAnalytics,
    private _snackBar: MatSnackBar) {
    super();
  }



  ngOnInit(): void {

    this.hasNotifications = ("Notification" in window);
    if (this.hasNotifications && Notification.permission !== 'default') this.notificationEnabled = true;

    // setup user.
    this.user$ = this.userService.user$();

    this.$.subscribe(this.user$, (user) => {
      this.user = user;
      if (user) {
        this.analytics.setUserId(user.uid);
        this.analytics.setUserProperties({
          tokens: user.fcmTokens?.length,
          providerId: user.providerId,
          has_notif: this.hasNotifications,
          notifications: this.hasNotifications ? Notification.permission : undefined,
        });
      }
    });


    let key$: Observable<string | null> = this.route.paramMap.pipe(map(params => params.get("week"))).pipe(shareReplay(1));
    key$.subscribe(k => this.key = k);
    const weekChanges$ = combineLatest([this.user$, key$]);


    this.$.subscribe(weekChanges$, ([user, weekId]) => {
      if (user && weekId) {
        this.weekService.updateMyPersonalValues(user, weekId);
      }
    });

    this.week$ = this.weekService.getWeek(key$);

    this.$.subscribe(this.week$, (week) => {
      if (!this.week && week) {
        const event = this.getEvent(week);
        this.analytics.logEvent("week_load", event);
      }
      this.week = week;
      if (week && week.title) {
        this.setTitle(week.title);
      }
    })

  }

  ngOnDestroy(): void {
    this.$.destroy();
  }


  getEvent(week: Week): any {
    const participant = week?.participants?.find(p => p.id === this.user?.uid);
    const firstTime = !(participant);
    const sjaak = !!(participant?.sjaak);
    const shift = week.options.find((option) => option.joined?.includes(this.user?.uid));
    const position = shift ? shift.joined.indexOf(this.user?.uid) + 1 : -1;
    const reserve = position === -1 ? undefined : position > shift.max;
    return {
      id: week.id,
      open: week.open,
      week: week.title.toLowerCase(),
      first_time: firstTime,
      sjaak: sjaak,
      shift: shift?.description,
      shift_size: shift?.joined.length,
      shift_max: shift?.max,
      reserve: reserve,
      positie: position
    };
  }

  touchWeek() {

  }

  requestPermission() {
    this.afMessaging.requestToken
      .subscribe(
        (token) => {
          const event = this.getEvent(this.week);
          event.allow = !!token;
          this.analytics.logEvent("change_notification", event);
          this.notificationEnabled = true;
          if (!token) {
            return;
          }

          this.afs.doc(`users/${this.user?.uid}`).update({ fcmTokens: firebase.default.firestore.FieldValue.arrayUnion(token) });
          this._snackBar.open("Notificatie aangezet", null, {
            duration: 2000,
          });
        },
        (error) => { console.error(error); },
      );
  }

  setTitle(title: string) {
    this.title = title;
    this.titleService.setTitle("Tovo Beach: " + title);
  }

  select(key: number) {
    if (this.week.options[key].joined?.includes(this.user?.uid)) return;

    const event = this.getEvent(this.week);
    event.new_shift = this.week.options[key].description;
    event.new_shift_size = this.week.options[key].joined?.length ?? 0;
    this.analytics.logEvent("play", event);

    this.weekService.play(this.user, key, this.week);
  }
  tochniet() {
    const event = this.getEvent(this.week);
    this.analytics.logEvent("sjaak", event);

    let update: any = {
      ['participants.' + this.user?.uid]: {
        id: this.user?.uid,
        name: this.user.displayName,
        time: firebase.default.firestore.FieldValue.serverTimestamp(),
        sjaak: true
      }
    };
    this.week.options.forEach((o, i) => {
      update['options.' + i + '.joined'] = firebase.default.firestore.FieldValue.arrayRemove(this.user?.uid);
    });
    this.afs.doc(`weeks/${this.week.id}`).update(update);
  }
  getSjaakDisabled(): boolean {
    if (!this.user) return true;
    if (!this.week) return true;
    if (!this.week.participants) return true;
    const participant = this.getParticipantFromWeek(this.week, this.user?.uid);
    if (!participant) return true;
    return participant.sjaak;
  }
  getParticipantFromWeek(week: Week, userId: string): Participant {
    return this.week.participants.find((p) => p.id === userId);
  }
}
