import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { User, Week } from '../../models/models';
import { Observable, of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { WeekService } from '../../services/week.service';
import { UserService } from '../../services/user.service';
import { BaseComponent } from '../../shared/base/base.component';

@Component({
  selector: 'tovo-beheer',
  templateUrl: './beheer.component.html',
  styleUrls: ['./beheer.component.scss']
})
export class BeheerComponent extends BaseComponent implements OnInit, OnDestroy {
  weeks: Week[];
  user$: Observable<User>;
  weeks$: Observable<Week[]>;
  allowed$: Observable<boolean>;

  constructor(
    private afs: AngularFirestore,
    private userService: UserService,
    private weekService: WeekService) {
    super();
  }

  ngOnInit(): void {
    this.user$ = this.userService.user$();
    this.weeks$ = this.weekService.getWeeks();
    this.$.subscribe(this.weeks$, (weeks: Week[]) => {
      this.weeks = weeks;
    });
  }
  share(week: Week) {
    window.navigator.share({
      title: week.title,
      url: "https://beach.tovo.nl/" + week.id,
      text: week.title
    })
  }

  ngOnDestroy(): void { this.destroy(); }

}
