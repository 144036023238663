import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

class Subber {

  list: Subscription[] = [];

  destroy() {
    this.list.forEach(sub => sub.unsubscribe());
  }
  subTo(obs: Observable<any>, fnCall: any) {
    let sub = obs.subscribe(fnCall);
    this.list.push(sub);
  }
  s = this.subTo;
  subscribe = this.subTo;
}

@Component({
  selector: 'tovo-base',
  template: ``
})
export class BaseComponent {
  destroy(): void {
    this.$.destroy();
  }
  $: Subber = new Subber();
}
