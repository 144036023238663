<tovo-page title="ToVolt store" [contentReady]="catalog && user" back>
    <!-- state-loggedin-weeks-admin -->
    <mat-card *ngIf="catalog && user">
        Vind de geactiveerde opties in het menu van je avatar (rechtsboven).
        <mat-list>
            <mat-list-item *ngFor="let product of catalog.products">
                <mat-icon matListIcon color="primary">{{product.icon}}</mat-icon>
                <div mat-line>Geef jezelf een kleur</div>
                <div mat-line *ngIf="hasPerk(product.perk)">{{product.price}} ⚡</div>
                <button mat-stroked-button color="primary" *ngIf="!hasPerk(product.perk)" (click)="buy(product)">
                    {{product.price}} ⚡
                </button>
                <button mat-stroked-button color="primary" disabled *ngIf="hasPerk(product.perk)">
                    <mat-icon>verified</mat-icon>
                </button>
            </mat-list-item>
        </mat-list>
    </mat-card>
</tovo-page>

<!--    <mat-card class="shop-tile">
        <mat-card-header>
            <tovo-avatar mat-card-avatar [ngStyle]="{'background': storeItem.color}">
                <ng-container numerator>{{storeItem.cost}}</ng-container>
                <ng-container denominator>⚡</ng-container>
            </tovo-avatar>
            <mat-card-title>{{storeItem.title}}</mat-card-title>
        </mat-card-header>
        <mat-card-content class="shop-content">
            <mat-card style="text-align: center;" class="example">
                <span [className]="storeItem.className">Jouw naam</span></mat-card>
            <br>
            <p>{{storeItem.description}}</p>
        </mat-card-content>
        <mat-card-actions class="controls">
            <mat-divider></mat-divider>
            <button mat-icon-button (click)="itemIndex= itemIndex-1">
                <mat-icon>chevron_left</mat-icon>
            </button>
            <button mat-icon-button >       
                <tovo-avatar mat-card-avatar [ngStyle]="{'background': storeItem.color}">
                <ng-container numerator>{{storeItem.cost}}</ng-container>
                <ng-container denominator>⚡</ng-container>
            </tovo-avatar>
            </button>
            <button mat-icon-button (click)="itemIndex= itemIndex+1">
                <mat-icon>chevron_right</mat-icon>
            </button>
        </mat-card-actions>
    </mat-card>
    -->