import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BeheerComponent } from './pages/beheer/beheer.component';
import { PickComponent } from './pages/pick/pick.component';
import { WeekviewComponent } from './pages/beheer/weekview/weekview.component';
import { WeeknewComponent } from './pages/beheer/weeknew/weeknew.component';
import { TovoltStoreComponent } from './pages/tovolt-store/tovolt-store.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';

const routes: Routes = [
  { path: 'beheer', component: BeheerComponent },
  { path: 'beheer/new', component: WeeknewComponent },
  { path: 'beheer/:week', component: WeekviewComponent },
  {
    path: 'store', component: TovoltStoreComponent
  },
  {
    path: ':week', component: PickComponent
  },
  {
    path: '', component: WelcomeComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
