
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { Week, Option } from 'src/app/models/models';
import { WeekService } from '../../../services/week.service';

@Component({
  selector: 'tovo-weeknew',
  templateUrl: './weeknew.component.html',
  styleUrls: ['./weeknew.component.scss']
})
export class WeeknewComponent implements OnInit {

  weekSize: number = 4;
  week: Week = {
    title: "",
    options: [
      { max: 36, period: "19:00 - 20:25", description: "Maandag shift 1" },
      { max: 36, period: "20:35 - 22:00", description: "Maandag shift 2" },
      { max: 36, period: "19:00 - 20:25", description: "Woensdag shift 1" },
      { max: 36, period: "20:35 - 22:00", description: "Woensdag shift 2" }
    ]
  } as Week;

  @ViewChild('stepper') stepper: MatStepper;
  constructor(
    private weekService: WeekService, 
    public dialog: MatDialog,
    private router: Router,
    private snackbar: MatSnackBar) { }

  ngOnInit(): void {
  }

  addOption(index?: number) {

    const isCopy = index === undefined;
    const nextStep = isCopy ? this.week.options.length : index + 1;
    const addStep = isCopy ? { max: 36, period: "", description: "" } : Object.assign({}, this.week.options[index]);
    this.week.options.splice(nextStep, 0, addStep as Option);
    setTimeout(() => {
      this.stepper.selectedIndex = nextStep;
    })
  }
  remove(index: number) {
    this.week.options.splice(index, 1);
  }
  clear(index: number) {
    this.week.options[index] = { max: 36, period: "", description: "" } as Option
  }

  async save() {
    const reference = await this.weekService.addWeek(this.week);
    this.snackbar.open(`${this.week.title} toegevoegd`);
    this.router.navigate(['beheer',reference.id]);
  }
}
