import { Component } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';

@Component({
  selector: 'tovo-root',
  template: `
      <router-outlet></router-outlet>
  `,
  styles: []
})
export class AppComponent {
  constructor() {

  }

}
