import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { User } from '../../../models/models';
import { Perk } from '../../../models/perk.enum';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'tovo-perkdialog',
  templateUrl: './perkdialog.component.html',
  styleUrls: ['./perkdialog.component.scss']
})
export class PerkdialogComponent implements OnInit {

  value: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { user: User, perk: Perk }, private afs: AngularFirestore,
    private dialogRef: MatDialogRef<PerkdialogComponent>) { }

  ngOnInit(): void {
    this.value = (this.data.user.perkValues) ? this.data.user.perkValues[this.data.perk] ?? "#000" : "#000";
  }

  setValue(event) {
    this.value = event;
  }
  save() {
    this.afs.doc(`users/${this.data.user.uid}`).update({ [`perkValues.${this.data.perk}`]: this.value }).then(() => {
      this.dialogRef.close();
    });

  }
}
