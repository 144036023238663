import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { Observable, of } from 'rxjs';
import { map, take, shareReplay, share } from 'rxjs/operators';
import { Catalog, Product } from '../models/models';
import { Perk } from '../models/perk.enum';
import { CatalogData, ProductData } from '../models/datamodels';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(private afs: AngularFirestore) { }

  buy(userId: string, product: Product): Promise<void> {
    return this.afs.doc(`users/${userId}`).update(
      {
        perks: firebase.default.firestore.FieldValue.arrayUnion(product.perk),
        toVolts: firebase.default.firestore.FieldValue.increment(product.price * -1)
      });
  }

  catalog$(): Observable<Catalog> {
    return this.afs.doc<Catalog>(`catalogs/tovo`).valueChanges().pipe(
      take(1),
      map(this.mapToCatalog),
      share()
    );
  }

  mapToCatalog(data: CatalogData): Catalog {
    const catalog = {} as Catalog;
    catalog.products = data.products;
    return catalog;
  }
  mapToProduct(productData: ProductData): Product {
    const product = {} as Product;
    product.description = productData.description;
    product.perk = Perk[productData.perk];
    product.price = productData.price;
    product.title = productData.title;
    product.visible = productData.visible;
    product.icon = productData.icon;
    return product;
  }
}
