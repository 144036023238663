<tovo-page title="Welkom" [contentReady]="{}">
    <mat-card>
        <mat-card-title>Welkom bij de Tovo App.</mat-card-title>
        <mat-card-content>
            Het lijkt er op dat je bent ingelogd, maar niet de specifieke link hebt gebruikt om bij een speelweek te
            komen. Dit kan wel eens voorkomen na het inloggen.<br>
            <br>
            Open de toegestuurde link nog eens..
        </mat-card-content>
    </mat-card>
</tovo-page>