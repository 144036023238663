import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tovo-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

  @Input()
  get line() { return this._line}
  set line(val) { this._line = coerceBooleanProperty(val);}
  private _line: boolean;

  @Input() color: string;
  constructor() { }

  ngOnInit(): void {
  }

}
