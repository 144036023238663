<ng-container *ngIf="data.perk === 'Color'"></ng-container>
<h2 mat-dialog-title>Kies je kleur</h2>
<mat-dialog-content class="color-perk">
    <mat-list>
        <mat-list-item class="normal">
            <div mat-line [ngStyle]="{'color': value}" class="name">
                {{data.user.displayName}}
            </div>
            <mat-icon>sports_volleyball</mat-icon>
        </mat-list-item>
        <mat-list-item class="selected">
            <div mat-line [ngStyle]="{'color': value, 'font-weight': 'bold'}" class="name">
                {{data.user.displayName}}
            </div>
            <mat-icon>check</mat-icon>
        </mat-list-item>
    </mat-list>
    <br>
    <span class="perk-color-picker" [(colorPicker)]="value" cpDialogDisplay="inline" cpToggle="true"
        cpAlphaChannel="disabled" cpOutputFormat="hex" (colorPickerChange)="setValue($event)"></span>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="save()" color="primary">Opslaan</button>
</mat-dialog-actions>