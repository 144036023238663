import { Component, Input, OnInit } from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/remote-config';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { User } from 'src/app/models/models';
import { UserService } from 'src/app/services/user.service';
import { TovoltStoreComponent } from '../../../pages/tovolt-store/tovolt-store.component';

@Component({
  selector: 'tovo-tovolt-avatar',
  templateUrl: './tovolt-avatar.component.html',
  styleUrls: ['./tovolt-avatar.component.scss']
})
export class TovoltAvatarComponent implements OnInit {

  toVolts: number;
  user$: Observable<User>;
  rc$: Observable<boolean>;
  constructor(
    private userService: UserService,
    private remoteConfig: AngularFireRemoteConfig,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.user$ = this.remoteConfig.booleans.enableStore.pipe(
      filter(show => show),
      switchMap(_ =>
        this.userService.user$()
      )
    );
  }

  openStore() {
    const dialogRef = this.dialog.open(TovoltStoreComponent, {

    });
  }

}
