import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of } from 'rxjs';
import { map, share, shareReplay, switchMap, tap } from 'rxjs/operators';
import { User } from '../models/models';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserData } from '../models/datamodels';
import { Perk, PerkValues } from '../models/perk.enum';
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class UserService {


  user: User;

  constructor(private auth: AngularFireAuth, private afs: AngularFirestore) {
    this.user$().subscribe(user => this.user = user);
  }

  withUserId = (_: string) => this.auth.user.pipe(
    map(user => {
      if (!user) return undefined;
      return user.uid;
    })
  );

  withUserData = (userId: string) => {
    if (!userId) return of(undefined);
    else return this.afs.doc<UserData>('users/' + userId).valueChanges();
  }

  toUser = (userData: UserData) => {
    if (!userData) return undefined;
    else return this.mapToUser(userData);
  }

  user$(): Observable<User> {
    return of('').pipe(
      switchMap(this.withUserId),
      switchMap(this.withUserData),
      map(this.toUser),
    )
  }

  mapToUser(userData: UserData): User {
    const user: User = {} as User;
    user.uid = userData.uid;
    user.perks = userData.perks?.map((perk) => Perk[perk]) ?? [];
    user.fcmTokens = userData.fcmTokens;
    user.displayName = userData.displayName;
    user.email = userData.email;
    user.isAdmin = userData.isAdmin;
    user.photoURL = userData.photoURL;
    user.toVolts = userData.toVolts ?? 0;
    user.phoneNumber = userData.phoneNumber;
    user.providerId = userData.providerId;
    user.perkValues = userData.perkValues;
    return user;
  }
}
