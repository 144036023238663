<div class="centered">
    <h1>Tovo Beach</h1>
    <div *ngIf="!(user$ | async)">

        <h3>Inloggen met google</h3>
        <div class="mat-elevation-z2 provider-button">
            <ngx-auth-firebaseui-providers [providers]="[providers.Google]"></ngx-auth-firebaseui-providers>
        </div>


        <h3>Registreren / Inloggen:</h3>
        <ngx-auth-firebaseui *ngIf="!(user$ | async)" [providers]="[]" [guestEnabled]="false"
            (onSuccess)="loggedin($event)" resetPasswordTabText="Reset">
        </ngx-auth-firebaseui>
    </div>
</div>