import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthProvider } from 'ngx-auth-firebaseui';

@Component({
  selector: 'tovo-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  providers = AuthProvider;
  user$: any;
  constructor(private router: Router, private route: ActivatedRoute, private auth: AngularFireAuth) { }

  ngOnInit(): void {
    this.user$ = this.auth.user;
  }

  loggedin(r: any) {
    setTimeout(() => {
window.location.reload();
},5000);
      
    //window.location.reload();
    //this.router.navigateByUrl(this.route.snapshot.queryParams.redirectUrl);
  }

  reload() {
    window.location.reload();
  }
}
