<div class="panel">
    <mat-toolbar color="primary">
        <button mat-icon-button *ngIf="back" (click)="previous()">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <button mat-icon-button *ngIf="dialog" (click)="openDialog()">
            <mat-icon>info</mat-icon>
        </button>
        <span style="flex: 1 1 auto;"></span>
        <span>{{title}}</span>
        <span style="flex: 1 1 auto;"></span>
        <tovo-tovolt-avatar></tovo-tovolt-avatar>
        <span>&nbsp;</span>
        <ngx-auth-firebaseui-avatar [links]="links$ | async"></ngx-auth-firebaseui-avatar>
    </mat-toolbar>
    <div class="cards master-background">
        <ng-container *ngIf="debugMsg">
            {{debugMsg}}
        </ng-container>
        <ng-container *ngIf="!authReady && !contentReady">
            <!-- state-loading -->
            <mat-card class="solo">
                <mat-spinner></mat-spinner>
                <p style="font-size: 16px;">{{loadingMessage}}</p>
            </mat-card>
        </ng-container>
        <ng-container *ngIf="authReady && !isUser">
            <!-- state-notloggedin -->
            <tovo-login></tovo-login>
        </ng-container>
        <ng-container *ngIf="authReady && isUser && !isAuth">
            <!-- state-notauthorized -->
            <mat-card class="solo">
                Geen toegang<br>
                Opkrassen ;)
            </mat-card>
        </ng-container>
        <ng-container *ngIf="authReady && isUser && isAuth && contentReady">
            <ng-content></ng-content>
        </ng-container>
    </div>
</div>