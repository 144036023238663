<tovo-page [title]="week?.title" [contentReady]="week" dialog>
    <!-- state-loggedin-week -->
    <ng-container *ngIf="week && !week.open">
        <mat-card>
            Week gesloten
        </mat-card>
    </ng-container>
    <ng-container *ngIf="week && week.open">
        <ng-container *ngFor="let option of week.options; let i = index;">
            <mat-card class="cardo"
                [ngClass]="{'selected-card': option.joined?.includes(this.user?.uid),'not-selected-card': !(option.joined?.includes(this.user?.uid)) }">
                <mat-card-header>
                    <div mat-card-avatar class="avatar">
                        <span class="j">{{option.joined?.length ? option.joined?.length : 0}}</span>
                        <span>/</span>
                        <span class="m">{{option.max}}</span>

                    </div>
                    <mat-card-title class="card-title">
                        {{option.description}}
                    </mat-card-title>
                    <mat-card-subtitle>
                        {{option.period}}
                    </mat-card-subtitle>
                    <span style="flex: 1 1 auto;"></span>
                    <button mat-stroked-button (click)="select(i)">
                        <mat-icon *ngIf="option.joined?.includes(this.user?.uid)">check</mat-icon>
                        <mat-icon *ngIf="!option.joined?.includes(this.user?.uid)">sports_volleyball</mat-icon>
                    </button>

                </mat-card-header>
                <tovo-names [me]="user?.uid"
                    [myColor]="user?.perks?.includes('Color') &&  user.perkValues ? user.perkValues['Color'] : 'initial'"
                    [shiftSize]="option.max" [participants]="week.participants" [joiners]="option.joined">
                </tovo-names>
            </mat-card>
        </ng-container>
        <mat-card class="intro-card">
            <button mat-raised-button (click)="requestPermission()" color="primary" *ngIf="!notificationEnabled"
                [disabled]="!hasNotifications">
                <mat-icon>notifications</mat-icon>
            </button>
            <div style="flex: auto; margin-left: 4px; margin-right: 4px; flex-direction: column;
            display: flex;">
                <span style="align-self: flex-start;" *ngIf="!hasNotifications">
                    ⬅ Jouw telefoon heeft geen notificaties
                </span>
                <span style="align-self: flex-start;" *ngIf="!notificationEnabled && hasNotifications">
                    ⬅ Notificatie aan zetten!
                </span>
                <span style="align-self: flex-end;">
                    Ook melden in Whatsapp! ➡
                </span>
            </div>
            <button mat-raised-button color="warn" (click)="tochniet()" [disabled]="getSjaakDisabled()">Sjaak</button>
        </mat-card>
    </ng-container>
</tovo-page>