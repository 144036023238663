import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Week } from 'src/app/models/models';

import { WeekService } from '../../../services/week.service';
import { BaseComponent } from '../../../shared/base/base.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';

@Component({
  selector: 'tovo-weekview',
  templateUrl: './weekview.component.html',
  styleUrls: ['./weekview.component.scss']
})
export class WeekviewComponent extends BaseComponent implements OnInit, OnDestroy {


  week: Week = {} as Week;
  beheerWeek$: Observable<Week>

  constructor(private route: ActivatedRoute, private weekService: WeekService, private dialog: MatDialog, private router: Router, private snackbar: MatSnackBar) { super() }

  ngOnInit(): void {
    this.beheerWeek$ = this.weekService.getWeek(
      this.route.params.pipe(map((params) => params['week']))
    );
    this.$.subscribe(this.beheerWeek$, (week) => this.week = week);
  }

  ngOnDestroy(): void {
    this.$.destroy();
  }

  share(week: Week) {
    window.navigator.share({
      title: week.title,
      url: "https://beach.tovo.nl/" + week.id,
      text: week.title
    })
  }
  
  toggleLock(week: Week): void{
    this.weekService.toggleLock(week.id, !week.open);
  }

  delete(week: Week): void {
    const title: string = week.title;
    this.dialog.open(DeleteDialogComponent, {
      data: week
    } ).afterClosed().subscribe(result => {
      if(result) {
        this.weekService.delete(week.id).then(() => {
          this.snackbar.open(`Week ${title} verwijderd`);
          this.router.navigate(['beheer']);
        }).catch((error) => {
          this.snackbar.open(`FOUT: ${error}`);
        });
        
      }
    });
  };
}
