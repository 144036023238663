<tovo-page title="Beheer" [contentReady]="weeks" admin> 
    <!-- state-loggedin-weeks-admin -->
    <mat-card style="display:flex;">
        <span class="spacer"></span>
        <button mat-raised-button color="primary" [routerLink]="['new']">Nieuwe week</button>
    </mat-card>
    <mat-nav-list>
        <mat-card>
            <div style="display:flex; width:100%; align-items: center;" *ngFor="let week of weeks">
                <mat-list-item [routerLink]="[week.id]">
                    <mat-icon mat-list-icon color="primary">{{week.open ? 'lock_open' : 'lock'}}</mat-icon>
                    <span mat-line>{{week.title}}</span>
                    <span mat-line>{{week.created | date}}</span>
                    <span class="spacer"></span>
                </mat-list-item>
                <button mat-icon-button color="accent" style="margin-left: 15px; margin-right: 15px;"
                    (click)="share(week)">
                    <mat-icon>share</mat-icon>
                </button>
            </div>
        </mat-card>
    </mat-nav-list>
</tovo-page>