import { isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';

import { AngularFireAnalyticsModule, CONFIG, DEBUG_MODE } from '@angular/fire/analytics';
import { AngularFireRemoteConfigModule, DEFAULTS, SETTINGS } from '@angular/fire/remote-config';

import { MatCardModule } from '@angular/material/card';
import { environment } from '../environments/environment';
import { LoginComponent } from './shared/page/login/login.component';
import { PickComponent } from './pages/pick/pick.component';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ColorPickerModule } from 'ngx-color-picker';

import { NamesComponent } from './pages/pick/names/names.component';
import { InfodialogComponent } from './shared/page/infodialog/infodialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BeheerComponent } from './pages/beheer/beheer.component';
import { WeekviewComponent } from './pages/beheer/weekview/weekview.component';
import { PageComponent } from './shared/page/page.component';
import { BaseComponent } from './shared/base/base.component';
import { WeeknewComponent } from './pages/beheer/weeknew/weeknew.component';
import { FormsModule } from '@angular/forms';
import { TovoltAvatarComponent } from './shared/page/tovolt-avatar/tovolt-avatar.component';
import { TovoltStoreComponent } from './pages/tovolt-store/tovolt-store.component';
import { AvatarComponent } from './shared/avatar/avatar.component';
import { DeleteDialogComponent } from './pages/beheer/weekview/delete-dialog/delete-dialog.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { PerkdialogComponent } from './shared/page/perkdialog/perkdialog.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PickComponent,
    NamesComponent,
    InfodialogComponent,
    BeheerComponent,
    WeekviewComponent,
    PageComponent,
    BaseComponent,
    WeeknewComponent,
    TovoltAvatarComponent,
    TovoltStoreComponent,
    AvatarComponent,
    DeleteDialogComponent,
    PerkdialogComponent,
    WelcomeComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxAuthFirebaseUIModule,
    MatCardModule,
    MatButtonModule,
    MatListModule,
    MatRadioModule,
    MatChipsModule,
    MatToolbarModule,
    MatIconModule,
    MatDialogModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatInputModule,
    MatStepperModule,
    MatSliderModule,
    MatProgressBarModule,
    ColorPickerModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireRemoteConfigModule,
    AngularFireMessagingModule,
    AngularFireAnalyticsModule,
    NgxAuthFirebaseUIModule.forRoot(environment.firebaseConfig
      ,
      () => 'tovo_beach_factory',
      {
        enableFirestoreSync: true, // enable/disable autosync users with firestore
        toastMessageOnAuthSuccess: true, // whether to open/show a snackbar message on auth success - default : true
        toastMessageOnAuthError: true, // whether to open/show a snackbar message on auth error - default : true
        authGuardFallbackURL: '/loggedout', // url for unauthenticated users - to use in combination with canActivate feature on a route
        authGuardLoggedInURL: '/loggedin', // url for authenticated users - to use in combination with canActivate feature on a route
        passwordMaxLength: 60, // `min/max` input parameters in components should be within this range.
        passwordMinLength: 6, // Password length min/max in forms independently of each componenet min/max.
        // Same as password but for the name
        nameMaxLength: 50,
        nameMinLength: 2,
        // If set, sign-in/up form is not available until email has been verified.
        // Plus protected routes are still protected even though user is connected.
        guardProtectedRoutesUntilEmailIsVerified: true,
        enableEmailVerification: false, // default: true
        useRawUserCredential: false, // If set to true outputs the UserCredential object instead of firebase.User after login and signup - Default: false
      }),

  ],
  providers: [
    {
      provide: CONFIG,
      useValue: {
        send_page_view: false,
        allow_ad_personalization_signals: false,
      },
    },
    {
      provide: DEBUG_MODE,
      useValue: !environment.production
    },
    { provide: DEFAULTS, useValue: { enableAwesome: true } },
    {
      provide: SETTINGS,
      useFactory: () => isDevMode() ? { minimumFetchIntervalMillis: 10_000 } : {}
    },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
