<h1 mat-dialog-title>Informatie Panel</h1>
<div mat-dialog-content>
    <ul style="padding: 0px">
        <li>Inschrijven mogelijk vanaf zondag 12:00</li>
        <li>Eén inschrijving per week</li>
        <li><b>Switchen van shift is achteraan sluiten</b></li>
        <li>Inschrijven na maximum is mogelijk</li>
        <li>Toch niet kunnen? Klik de Sjaak knop!</li>
        <li>Reserve doet mee na een Sjaak!</li>

    </ul>
    <p>Analytics wordt gebruikt om app te verbeteren.</p>
    <p class="subtext">V0.7.0</p>
    <p class="subtext">Dit tooltje is gemaakt door Rik! Geef 'm maar een biertje!</p>
</div>
<div mat-dialog-actions style="justify-content: flex-end;">
    <button mat-button [mat-dialog-close]="true" color="primary" cdkFocusInitial>Ok</button>
</div>