import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LinkMenuItem } from 'ngx-auth-firebaseui';
import { combineLatest, Observable } from 'rxjs';
import { Catalog, User } from 'src/app/models/models';
import { UserService } from '../../services/user.service';
import { BaseComponent } from '../base/base.component';
import { InfodialogComponent } from './infodialog/infodialog.component';
import { StoreService } from '../../services/store.service';
import { map, switchMap } from 'rxjs/operators';
import { Perk } from 'src/app/models/perk.enum';
import { PerkdialogComponent as PerkDialogComponent } from './perkdialog/perkdialog.component';
import { Location } from '@angular/common'
@Component({
  selector: 'tovo-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input() title: string;
  @Input() contentReady: any;

  @Input()
  get admin(): boolean { return this._admin; }
  set admin(val: boolean) { this._admin = coerceBooleanProperty(val); }
  private _admin: boolean;

  @Input()
  get back(): boolean { return this._back; }
  set back(val: boolean) { this._back = coerceBooleanProperty(val); }
  private _back: boolean;

  @Input()
  get dialog(): boolean { return this._dialog; }
  set dialog(val: boolean) { this._dialog = coerceBooleanProperty(val); }
  private _dialog: boolean;

  isUser: boolean = false;
  isAuth: boolean = false;
  authReady: boolean = false;
  links$: Observable<LinkMenuItem[]>;
  catalog: Catalog;

  loadingMessages = [
    "📞 Hallo?",
    "✋ High five?",
    "🍑 *slap*",
    "🦄 Magisch",
    "🐭 Piep!",
    "❤️ Liefde!",
    "🍆 Hmm",
    "🏐 Hups!",
    "🐴 Te Paard!",
    "⚡ ToVolt!",
    "✨ Hokus!",
    "✨ Pokus!",
    "✨ Simsalabim!",
    "🌹 Bloempje?",
    "🚿 Lalala! 🎵",
    "🤖 LET'S GO!",
    "🎺 Toooooet!",
    "🐢 Rustaaahg",
    "👂 Pssst..."
  ];


  debugMsg: string = "";
  loadingMessage: string;

  constructor(
    private matDialog: MatDialog,
    private userSerivce: UserService,
    private storeService: StoreService,
    private location: Location
  ) { super(); }


  ngOnInit(): void {
    if (window.location.search.indexOf('debug') !== -1) this.enableDebug();

    this.loadingMessage = this.getLoadingMessage();
    const user$ = this.userSerivce.user$();
    const catalog$ = this.storeService.catalog$();
    this.$.subscribe(user$, (user) => {

      this.isUser = !!(user);
      this.isAuth = this.needsAndIsAdmin(user);
      this.authReady = true;
    });
    this.$.subscribe(catalog$, (catalog: Catalog) => {
      this.catalog = catalog;
    });
    this.links$ = combineLatest([user$, catalog$]).pipe(
      map(([user, catalog]) => {
        return this.getLinks(user, catalog);
      })
    );
  }

  ngOnDestroy(): void {
    this.$.destroy();
  }

  getLinks(user: User, catalog: Catalog): LinkMenuItem[] {
    if(!user || !user.perks) return [];
    return catalog.products
      .filter((product) => user.perks.some((perk) => product.perk == perk))
      .map((product) => {
        return { icon: product.icon, text: product.title, callback: () => { this.openPerkDialog(user, product.perk) } } as LinkMenuItem;
      });
  }

  needsAndIsAdmin(user: User): boolean {
    if (!user) return false;
    if (!this.admin) return true;
    return this.admin && user.isAdmin;
  }
  enableDebug() {
    console.log = (a, b, c, d) => {
      this.debugMsg += [a, b, c, d].join("<br>");
    }
    console.warn = (a, b, c, d) => {
      this.debugMsg += [a, b, c, d].join("<br>");
    }
    console.error = (a, b, c, d) => {
      this.debugMsg += [a, b, c, d].join("<br>");
    }
  }

  getLoadingMessage() {
    const item = Math.floor(Math.random() * this.loadingMessages.length);
    return this.loadingMessages[item];
  };

  openDialog() {
    return this.matDialog.open(InfodialogComponent, {

    });
  }

  openPerkDialog(user: User, perk: Perk) {
    return this.matDialog.open(PerkDialogComponent, {
      data: { user: user, perk: perk },
      autoFocus: false
    });
  }

  previous() {
    this.location.back();
  }
}
