import { Component, OnDestroy, OnInit } from '@angular/core';
import { Catalog, Product, User } from 'src/app/models/models';
import { Perk } from 'src/app/models/perk.enum';
import { StoreService } from 'src/app/services/store.service';
import { UserService } from 'src/app/services/user.service';
import { BaseComponent } from 'src/app/shared/base/base.component';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface StoreItem {
  title: string;
  description: string;
  cost: number;
  key: string;
  color: string;
  className: string;
}


@Component({
  selector: 'tovo-tovolt-store',
  templateUrl: './tovolt-store.component.html',
  styleUrls: ['./tovolt-store.component.scss']
})
export class TovoltStoreComponent extends BaseComponent implements OnInit, OnDestroy {

  user: User;

  colorPerk: Perk = Perk.Color;
  catalog: Catalog;

  constructor(
    private userService: UserService,
    private storeService: StoreService,
    private matSnackBar: MatSnackBar) {
    super();
  }

  ngOnInit(): void {
    const user$ = this.userService.user$();
    this.$.subscribe(user$, (user) => this.user = user);

    const catalog$ = this.storeService.catalog$();
    this.$.subscribe(catalog$, (catalog) => this.catalog = catalog);
  }

  ngOnDestroy(): void {
    this.$.destroy();
  }

  hasPerk(perk: Perk): boolean {
    if (!this.user.perks) return false;
    return this.user.perks.indexOf(perk) !== -1;
  }

  async buy(product: Product): Promise<void> {
    if (!this.hasPerk(product.perk))
      return this.storeService.buy(this.user.uid, product).then(() => {
        this.matSnackBar.open("Perk gekocht");
      });
    else
      return Promise.reject();
  }
}

/*
  storeItems$: Observable<StoreItem[]>;
  storeItems: StoreItem[];
  storeItemsArr: StoreItem[] = [
    {
      title: "Naam kleur",
      description: "Kies je eigen naam kleur! Super hip!",
      cost: 40,
      key: "nameColor",
      color: "#5c6bc0",
      className: "colored-text"
    },
    {
      title: "Regenboog naam",
      description: "Dikke shine met je naam!! Hups!",
      cost: 90,
      key: "nameColor",
      color: "#5c6bc0",
      className: "rainbow-text"
    },
    {
      title: "Achtergrond kleur",
      description: "Maak je naam nog toffer met een custom achtergrond kleur!",
      cost: 40,
      key: "nameBackgroundColor",
      color: "#827717",
      className: "rainbow-background"
    },    {
      title: "Complimentje maken",
      description: "Lekker PosoToVo zijn. Geef iemand een complimentje! (mag anoniem!)",
      cost: 20,
      key: "complimentOne",
      color: "#00796b",
      className: 'compliment'
    }
  ];
  itemIndex:  number = 0;
  get storeItem(): StoreItem {
    return this.storeItems[Math.abs(this.itemIndex % this.storeItems.length)];
  }

   this.storeItems$ = of(this.storeItemsArr);
    this.storeItems$.subscribe((storeItems) => {
      this.storeItems = storeItems;
    });
*/
