<tovo-page [title]="week.title" [contentReady]="week" back admin>
    <mat-card>
        <mat-card-header>
            <button mat-icon-button mat-card-avatar (click)="toggleLock(week)">
                <mat-icon color="primary">{{week.open ? 'lock_open' : 'lock'}}</mat-icon>
            </button>
            <mat-card-title>{{week.title}}</mat-card-title>
            <mat-card-subtitle>Gemaakt: {{week.created | date}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <a href="https://beach.tovo.nl/{{week.id}}">https://beach.tovo.nl/{{week.id}}</a>
        </mat-card-content>
        
        <mat-list>

            <mat-list-item *ngFor="let option of week.options" dense>
                <mat-icon mat-list-icon color="primary">groups</mat-icon>
                <div class="progress">
                    <mat-progress-bar style="flex:4" [value]="(option.joined?.length / option.max) * 100" color="primary"></mat-progress-bar>
                    <mat-progress-bar style="flex:1" [value]="((option.joined?.length - option.max) / option.max * 4) * 100" color="warn"></mat-progress-bar>
                </div>
                <div mat-line>{{option.description}}</div>
                <div mat-line>{{option.period}}</div>
                <span>{{option.joined?.length}}/{{option.max}}</span>
            </mat-list-item>
        </mat-list>
        <mat-card-actions>
            <button mat-button (click)="delete(week)" color="warn">Verwijderen</button>
            <button mat-button (click)="share(week)" color="primary">Delen</button>
        </mat-card-actions>
    </mat-card>
</tovo-page>