import { Component, Input, OnInit } from '@angular/core';
import { Participant } from '../../../models/models';


@Component({
  selector: 'tovo-names',
  templateUrl: './names.component.html',
  styleUrls: ['./names.component.scss']
})
export class NamesComponent implements OnInit {

  @Input() me: string;
  @Input() myColor: string;
  @Input() participants: Participant[];
  @Input() joiners: string[];
  @Input() shiftSize: number = 30;

  firsts: Participant[] = [];
  reserve: Participant[] = [];

  constructor() {
  }

  ngOnInit(): void {
    const list = this.sortJoinerByParticipantTime(this.joiners, this.participants);

    this.firsts = list.slice(0, this.shiftSize);
    this.reserve = list.slice(this.shiftSize);
  }

  sortJoinerByParticipantTime(joiners: string[], participants: Participant[]): Participant[] {
    if (!joiners) return [];
    const list = joiners.map((j) => participants?.find(p => p.id === j)).filter(p => !!p);
    return list.sort((a, b) => a?.time && b?.time ? a.time.valueOf() - b.time.valueOf() : 1);
  }

}

