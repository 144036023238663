<tovo-page title="Nieuwe week" [contentReady]="true" back admin>
    <mat-card>
        <div>
            <mat-form-field appearance="fill">
                <mat-label>Titel</mat-label>
                <input matInput placeholder="" [(ngModel)]="week.title" required>
            </mat-form-field>
        </div>
        <mat-vertical-stepper #stepper>
            <mat-step *ngFor="let option of week.options; index as i">
                <ng-template matStepLabel>Optie {{i+1}} - {{option?.description}}</ng-template>
                <mat-form-field appearance="fill">
                    <mat-label>Shift naam</mat-label>
                    <input matInput placeholder="Dag shift X" [(ngModel)]="option.description">
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Periode</mat-label>
                    <input matInput type="string" placeholder="hh:mm - hh:mm" [(ngModel)]="option.period">
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Max</mat-label>
                    <input matInput type="number" placeholder="" [(ngModel)]="option.max">
                </mat-form-field>

                <button mat-button color="warn" (click)="remove(i)">
                    <mat-icon>delete_outlined</mat-icon>
                </button>
                <button mat-button (click)="clear(i)">
                    <mat-icon>clear</mat-icon>
                </button>
                <button mat-button (click)="addOption(i)">
                    <mat-icon>copy_all</mat-icon>
                </button>
                <button mat-button color="primary" *ngIf="i !== week.options.length - 1" matStepperNext>
                    <mat-icon>skip_next</mat-icon>
                </button>
                <button mat-button color="primary" *ngIf="i === week.options.length - 1" (click)="addOption()">
                    <mat-icon>add</mat-icon>
                </button>
            </mat-step>

        </mat-vertical-stepper>
        <mat-card-actions>
            <button mat-raised-button (click)="save()" color="primary">Opslaan </button>
        </mat-card-actions>
    </mat-card>
</tovo-page>